var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-pull-to-refresh",
        {
          attrs: {
            handler: _vm.getProducts,
            "refresh-icon": "ion-sync",
            "release-message": _vm.$t("PTR.RELEASE"),
            "refresh-message": _vm.$t("PTR.REFRESH"),
            "pull-message": _vm.$t("PTR.PULL"),
            color: "faded"
          }
        },
        [
          _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
          _vm.ready
            ? _c(
                "q-page",
                [
                  !_vm.products.length
                    ? _c("div", { staticClass: "column items-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-center full-width",
                            style:
                              _vm.ecosystem_id === "foods"
                                ? ""
                                : "padding-top:20vh"
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.ecosystem_id === "foods"
                                  ? [
                                      _c(
                                        "q-card",
                                        {
                                          key: "tg-tasks05",
                                          staticClass:
                                            "q-card-grouped text-center no-shadow no-border no-background flex-auto width-auto"
                                        },
                                        [
                                          _c(
                                            "q-card-main",
                                            {
                                              staticClass:
                                                "column justify-center full-height"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-xs-auto" },
                                                [
                                                  _c("q-btn", {
                                                    staticClass:
                                                      "limit-width-1024 full-width text-family-brand text-weight-semibold uppercase",
                                                    attrs: {
                                                      color: "secondary-light",
                                                      "text-color": "secondary",
                                                      rounded: "",
                                                      label: "Personalize"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _c("img", {
                                  staticClass: "hero dark-img-hero",
                                  attrs: {
                                    src:
                                      "statics/ecosystems/" +
                                      _vm.ecosystem_id +
                                      "/icon.svg"
                                  }
                                })
                              ],
                              2
                            ),
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-family-brand text-weight-regular"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.getEcosystemLabel("ADD_L"))
                                    ) + " "
                                  ),
                                  _c("q-icon", {
                                    staticClass: "vertical-middle",
                                    staticStyle: { "margin-left": "4px" },
                                    attrs: { name: "ion-add-circle" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    : [
                        _vm.ecosystem.ecosystem_id === "business"
                          ? _c("div", { staticClass: "items-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-between items-center margin-auto-lr datagroup-container on-top-xl"
                                },
                                [
                                  _vm._l(_vm.products, function(
                                    product,
                                    index
                                  ) {
                                    return [
                                      _c(
                                        "transition",
                                        {
                                          key: product.id + "-trx",
                                          attrs: {
                                            appear: "",
                                            "enter-active-class":
                                              "animated fadeInUp animated-d200"
                                          }
                                        },
                                        [
                                          _c(
                                            "q-card",
                                            {
                                              directives: [
                                                {
                                                  name: "touch-pan",
                                                  rawName:
                                                    "v-touch-pan.noMouse",
                                                  value: function(obj) {
                                                    _vm.setCardIntent(
                                                      obj,
                                                      product.id,
                                                      _vm.processPropertySelection
                                                    )
                                                  },
                                                  expression:
                                                    "(obj) => { setCardIntent(obj, product.id, processPropertySelection) }",
                                                  modifiers: { noMouse: true }
                                                }
                                              ],
                                              key: product.id,
                                              ref:
                                                "property-card-" + product.id,
                                              refInFor: true,
                                              staticClass:
                                                "q-card-grouped q-card-widget overflow-hidden",
                                              staticStyle: {
                                                "margin-top": "22px"
                                              },
                                              attrs: {
                                                inline: "",
                                                "data-index": index
                                              }
                                            },
                                            [
                                              _c(
                                                "q-card-media",
                                                {
                                                  staticStyle: {
                                                    "max-height": "222px",
                                                    "min-height": "222px",
                                                    "background-repeat":
                                                      "no-repeat",
                                                    "background-size": "cover",
                                                    "background-position":
                                                      "center top"
                                                  },
                                                  style: _vm.showImages
                                                    ? "background-image: url(" +
                                                      _vm.getProductPhotoResource(
                                                        product.data.media
                                                          .photos.resources
                                                      ) +
                                                      ")"
                                                    : "min-height: 0; max-height: 0; height: 0;"
                                                },
                                                [
                                                  !_vm.getProductPhotoResource(
                                                    product.data.media.photos
                                                      .resources
                                                  )
                                                    ? _c("img", {
                                                        staticClass:
                                                          "no-photos",
                                                        staticStyle: {
                                                          height: "222px",
                                                          "background-color":
                                                            "#9B9B9B"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "q-card-title",
                                                {
                                                  staticClass: "line-height-sm"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        product.data.business
                                                          .name
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-family-brand text-weight-medium block",
                                                      attrs: {
                                                        slot: "subtitle"
                                                      },
                                                      slot: "subtitle"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            product.data
                                                              .business.address
                                                              .full
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      staticStyle: {
                                                        "min-width": "40px"
                                                      },
                                                      attrs: { slot: "right" },
                                                      slot: "right"
                                                    },
                                                    [
                                                      _c("q-icon", {
                                                        attrs: {
                                                          name:
                                                            "ion-" +
                                                            _vm.type2IconName(
                                                              product.data
                                                                .business
                                                                .types[0]
                                                            ),
                                                          color: "secondary",
                                                          size: "40px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "q-card-main",
                                                {
                                                  staticClass:
                                                    "verified text-family-brand flex justify-center no-padding-top text-weight-semibold text-grey font-size-120p capitalize"
                                                },
                                                [
                                                  product.data.channel
                                                    .online === 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-educate heartbeat"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("LIVE")
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "OFFLINE.LABEL"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                ]
                                              ),
                                              _c(
                                                "q-card-main",
                                                {
                                                  staticClass:
                                                    "hidden verified text-family-brand flex justify-center no-padding-top capitalize"
                                                },
                                                [
                                                  product.verified ||
                                                  product.verified_status !=
                                                    "NONE"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("q-icon", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !product.verified,
                                                                expression:
                                                                  "!product.verified"
                                                              }
                                                            ],
                                                            class: {
                                                              "animate-spin":
                                                                product.verified_status !=
                                                                "VERIFIED",
                                                              "text-primary":
                                                                product.verified,
                                                              "text-secondary": !product.verified
                                                            },
                                                            attrs: {
                                                              size: "22px",
                                                              name: "ion-flower"
                                                            }
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-weight-medium",
                                                              class: {
                                                                "text-secondary": !product.verified,
                                                                "text-primary":
                                                                  product.verified
                                                              },
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "4px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "VERIFIED_" +
                                                                      product.verified_status
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          product.verified_status ===
                                                          "VERIFIED"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-subinfo-l text-weight-regular"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "on " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "tformat"
                                                                        )(
                                                                          product.verified_timestamp
                                                                        )
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-wing text-weight-semibold font-size-140p"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "VERIFIED_NOT"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                ]
                                              ),
                                              _c("q-card-separator", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.$q.platform.has
                                                      .touch,
                                                    expression:
                                                      "!$q.platform.has.touch"
                                                  }
                                                ]
                                              }),
                                              _c(
                                                "q-card-actions",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: !_vm.$q.platform
                                                        .has.touch,
                                                      expression:
                                                        "!$q.platform.has.touch"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _c(
                                                    "q-btn",
                                                    {
                                                      staticClass: "full-width",
                                                      attrs: {
                                                        color: "primary",
                                                        flat: "",
                                                        rounded: "",
                                                        label: _vm.$t("VIEW")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.processPropertySelection(
                                                            product.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "q-tooltip",
                                                        {
                                                          attrs: {
                                                            delay: 2000,
                                                            inverted: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  _vm.getEcosystemLabel(
                                                                    "VIEW.LABEL_TT"
                                                                  )
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ],
                  _vm.products.length
                    ? _c("l-footer", { attrs: { simple: "" } })
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "q-page-sticky",
        { attrs: { position: "bottom-right", offset: [20, 20] } },
        [
          _c(
            "q-btn",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              staticClass: "shadow-24",
              attrs: { size: "lg", round: "", push: "", color: "primary" },
              on: { click: _vm.addProductInit }
            },
            [
              _c("img", {
                attrs: { src: "/statics/_demo/plus_white.svg", width: "22" }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          staticClass: "appLayer dialog-item",
          attrs: {
            position: "bottom",
            "no-esc-dismiss":
              _vm.dialogMyProductsAdd.busy || _vm.dialogMyProductsAdd.done,
            "no-backdrop-dismiss":
              _vm.dialogMyProductsAdd.busy || _vm.dialogMyProductsAdd.done
          },
          model: {
            value: _vm.dialogMyProductsAdd.show,
            callback: function($$v) {
              _vm.$set(_vm.dialogMyProductsAdd, "show", $$v)
            },
            expression: "dialogMyProductsAdd.show"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c("q-toolbar-title", [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.getEcosystemLabel("ADD_YOUR_PRODUCT")))
                    )
                  ])
                ],
                1
              ),
              _c(
                "q-toolbar",
                {
                  staticClass: "toolbar-overscroll-shadow",
                  attrs: { slot: "header", inverted: "" },
                  slot: "header"
                },
                [
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                    },
                    [
                      _c(
                        "q-card-main",
                        { staticClass: "column justify-center full-height" },
                        [
                          _c(
                            "q-btn",
                            {
                              staticClass:
                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                              attrs: {
                                disabled: _vm.dialogMyProductsAdd.busy,
                                color: "primary-light",
                                "text-color": "primary",
                                rounded: ""
                              },
                              nativeOn: {
                                click: function($event) {
                                  _vm.dialogMyProductsAdd.show = false
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("DONE")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "layout-padding no-padding-top",
                  attrs: { "style-ignore": "padding-top: 0.5rem" }
                },
                [
                  _c("q-scroll-observable", {
                    on: { scroll: _vm.toolbarShadowOnOverscroll }
                  }),
                  _c(
                    "span",
                    {
                      class: {
                        invisible: _vm.dialogMyProductsAdd.slide === 0,
                        "z-top relative-position block animated fadeInUp":
                          _vm.dialogMyProductsAdd.slide > 0 &&
                          _vm.dialogMyProductsAdd.slide < 4,
                        "z-top relative-position block animated fadeOutUp":
                          _vm.dialogMyProductsAdd.slide === 4
                      }
                    },
                    [
                      _c("vue-autosuggest", {
                        staticClass: "ais-input-autocomplete",
                        attrs: {
                          "get-suggestion-value": _vm.getSuggestionValue,
                          suggestions:
                            _vm.dialogMyProductsAdd.address.suggestion,
                          "input-props": {
                            placeholder: _vm.$t(
                              _vm.getEcosystemLabel("ENTER_YOUR_PRODUCT")
                            ),
                            onInputChange: _vm.refine,
                            class: "input-autosuggest capitalize"
                          },
                          disabled: _vm.dialogMyProductsAdd.busy
                        },
                        on: { selected: _vm.selectProduct, input: _vm.refine },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var suggestion = ref.suggestion
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row justify-between content-stretch overflow-hidden cursor-pointer"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "full-width capitalize text-weight-semibold text-black",
                                        staticStyle: {
                                          "line-height": "1rem",
                                          "margin-bottom": "6px",
                                          "margin-left": "2px"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-center float-left",
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "margin-right": "8px",
                                              "padding-bottom": "40px"
                                            }
                                          },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                name:
                                                  "ion-" +
                                                  _vm.type2IconName(
                                                    suggestion.item.types[0]
                                                  ),
                                                color: "secondary",
                                                size: "24px"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              suggestion.item
                                                .structured_formatting.main_text
                                            ) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block text-tertiary text-weight-regular animated fadeInUp animated-d200",
                                            staticStyle: { "margin-top": "4px" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  suggestion.item
                                                    .structured_formatting
                                                    .secondary_text
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.dialogMyProductsAdd.query,
                          callback: function($$v) {
                            _vm.$set(_vm.dialogMyProductsAdd, "query", $$v)
                          },
                          expression: "dialogMyProductsAdd.query"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "q-carousel",
                    {
                      attrs: {
                        "no-swipe": "",
                        height: "48vh",
                        easing: _vm.dialogMyProductsAdd.overshoot,
                        animation: 1000
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "control-progress",
                          fn: function(carousel) {
                            return _c(
                              "q-carousel-control",
                              { attrs: { position: "bottom" } },
                              [
                                _c("q-progress", {
                                  staticClass: "absolute animated",
                                  class: {
                                    invisible:
                                      _vm.dialogMyProductsAdd.slide === 0,
                                    fadeInUp:
                                      _vm.dialogMyProductsAdd.slide === 1,
                                    fadeOut: _vm.dialogMyProductsAdd.slide === 4
                                  },
                                  staticStyle: { top: "0px" },
                                  attrs: {
                                    percentage: carousel.percentage,
                                    color: "secondary",
                                    stripe: "",
                                    animate: "",
                                    height: "12px"
                                  }
                                })
                              ],
                              1
                            )
                          }
                        }
                      ]),
                      model: {
                        value: _vm.dialogMyProductsAdd.slide,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogMyProductsAdd, "slide", $$v)
                        },
                        expression: "dialogMyProductsAdd.slide"
                      }
                    },
                    [
                      _c(
                        "q-carousel-slide",
                        {
                          key: "dialogMyProductsAddSlide0",
                          staticClass: "flex flex-center"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand text-weight-bolder q-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("PREPARE_SPOT")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand q-subheading text-faded",
                                staticStyle: { "margin-bottom": "20vh" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("PREPARE_SPOT_D")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-center",
                                class: {
                                  "animated fadeIn":
                                    _vm.dialogMyProductsAdd.slide === 0
                                },
                                staticStyle: { padding: "4px", height: "20vh" }
                              },
                              [
                                _c("q-spinner-puff", {
                                  attrs: { size: "80px", color: "secondary" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "q-carousel-slide",
                        {
                          key: "dialogMyProductsAddSlide1",
                          staticClass: "flex flex-center"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand text-weight-bolder q-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("PRODUCT_LOCATION")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand q-subheading text-faded",
                                staticStyle: { "margin-bottom": "20vh" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel(
                                        "PRODUCT_LOCATION_D"
                                      )
                                    )
                                  )
                                )
                              ]
                            ),
                            _c("img", {
                              staticClass: "dark-img-invert-70",
                              class: {
                                "animated fadeIn":
                                  _vm.dialogMyProductsAdd.slide === 1
                              },
                              staticStyle: { padding: "4px", height: "10vh" },
                              attrs: { src: "/statics/_demo/mappin.svg" }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "q-carousel-slide",
                        {
                          key: "dialogMyProductsAddSlide2",
                          staticClass: "flex flex-center"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand text-weight-bolder q-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.getEcosystemLabel("SEARCHING"))
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand q-subheading text-faded",
                                staticStyle: { "margin-bottom": "20vh" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.getEcosystemLabel("SEARCHING_D"))
                                  )
                                )
                              ]
                            ),
                            _c("img", {
                              staticClass: "dark-img-invert-70",
                              class: {
                                "animated fadeIn":
                                  _vm.dialogMyProductsAdd.slide === 2
                              },
                              staticStyle: { padding: "4px", height: "10vh" },
                              attrs: {
                                src: "/statics/_demo/magnifyingglass.svg"
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "q-carousel-slide",
                        {
                          key: "dialogMyProductsAddSlide3",
                          staticClass: "flex flex-center"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand text-weight-bolder q-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("FOUND_PRODUCT")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand q-subheading text-faded",
                                staticStyle: { "margin-bottom": "20vh" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("FOUND_PRODUCT_D")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c("img", {
                              staticClass: "dark-img-invert-70",
                              class: {
                                "animated fadeIn":
                                  _vm.dialogMyProductsAdd.slide === 3
                              },
                              staticStyle: { padding: "4px", height: "10vh" },
                              attrs: {
                                src: "/statics/_demo/signal_primary.svg"
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "q-carousel-slide",
                        {
                          key: "dialogMyProductsAddSlide4",
                          staticClass: "flex flex-center"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand text-weight-bolder q-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("PRODUCT_READY")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-family-brand q-subheading text-faded",
                                staticStyle: { "margin-bottom": "20vh" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getEcosystemLabel("PRODUCT_READY_D")
                                    )
                                  )
                                )
                              ]
                            ),
                            _c("img", {
                              staticClass: "dark-img-invert-70",
                              class: {
                                "animated fadeInRight":
                                  _vm.dialogMyProductsAdd.slide === 4
                              },
                              staticStyle: { padding: "4px", height: "10vh" },
                              attrs: {
                                src: "/statics/_demo/checkmark_green.svg"
                              }
                            })
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "q-modal",
        {
          attrs: {
            minimized: "",
            "no-esc-dismiss": "",
            "no-backdrop-dismiss": ""
          },
          model: {
            value: _vm.dialogRemovingShow,
            callback: function($$v) {
              _vm.dialogRemovingShow = $$v
            },
            expression: "dialogRemovingShow"
          }
        },
        [
          _c(
            "q-modal-layout",
            [
              _c(
                "q-toolbar",
                { attrs: { slot: "header", inverted: "" }, slot: "header" },
                [
                  _c("q-toolbar-title", [
                    _vm._v(
                      _vm._s(_vm.$t(_vm.getEcosystemLabel("DELETE.DELETING")))
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout-padding" },
                [
                  _c("q-progress", {
                    attrs: {
                      indeterminate: "",
                      color: "secondary",
                      stripe: "",
                      animate: "",
                      height: "12px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }